import { simpleSlider } from "./components/simpleSlider";

import { initComponent } from "./components/initComponent";
import { viewProduct } from "./helper/helper";

initComponent();

const heroSection = document.querySelector(".section__hero");
const heroSlides = document.querySelectorAll(".hero__slide");
const headerEl = document.querySelector(".header");

// simpleSlider(heroSection, true, 1, 0);
simpleSlider(heroSection, true, 1, 10000);

const headerFn = function () {
  const scroller = document.querySelector(".pseudo-scroll");
  if (window.innerWidth < 450) {
    heroSection.classList.remove("hero-fixed");
    scroller.classList.add("hidden");
    headerEl.classList.remove("header--hidden");
    return;
  }
  window.addEventListener("scroll", (e) => {
    if (
      window.innerWidth <= 820 ||
      (window.scrollY > scroller.clientHeight * 0.33 &&
        heroSection.classList.contains("hero-fixed"))
    ) {
      heroSection.classList.remove("hero-fixed");
      scroller.classList.add("hidden");
      headerEl.classList.remove("header--hidden");
      return;
    }
    if (window.scrollY > 0 && heroSection.classList.contains("hero-fixed")) {
      heroSlides.forEach((slide) => {
        slide.style.borderWidth = `${
          window.scrollY *
          ((window.innerWidth > 600 ? 8 : 3) / (scroller.clientHeight * 0.33))
        }rem`;
      });
    }
    if (window.scrollY === 0) {
      headerEl.classList.add("header--hidden");
      setTimeout(function () {
        heroSection.classList.add("hero-fixed");
        scroller.classList.remove("hidden");
        heroSlides.forEach((slide) => (slide.style.borderWidth = `0rem`));
        console.log(`condition 3 ${(window.scrollY, scroller.clientHeight)}`);
        return;
      }, 500);
    }
    return;
  });
};

headerFn();
const ourCustomers = document.querySelector(".our-customers__container");

//testimonials section
simpleSlider(ourCustomers, false, 1, 10000);
