export const simpleSlider = function (
  queryTarget = document,
  // eventTarget = undefined,
  showDots = false,
  scaleAnimation = 1,
  autoPlay = 0
) {
  const allSlides = Array.from(
    queryTarget.querySelectorAll(".simpleslider__slide")
  );
  const eventSlider = queryTarget.querySelector(".simpleslider");

  const slider = queryTarget.querySelector(".simpleslider");
  const slide = queryTarget.querySelector(".simpleslider__slide");
  const btnNext = queryTarget.querySelector(".btn--next");
  const btnPrev = queryTarget.querySelector(".btn--previous");

  const sliderWidth = slider.clientWidth;
  const slideWidth = slide.clientWidth;
  console.log(slideWidth, slideWidth);
  const computeGap = function () {
    if (Number.isNaN(+window.getComputedStyle(slider).gap.slice(0, -2)))
      return 0;
    else return +window.getComputedStyle(slider).gap.slice(0, -2);
  };

  const visibleSlides = Math.trunc(sliderWidth / (slideWidth - computeGap()));
  const counter = allSlides.length - visibleSlides;

  let isDragging = false,
    startPostion = 0,
    currentPosition = 0,
    drag = 0,
    currentTranslate = 0,
    previousTranslate = 0,
    currentIndex = 0,
    dotIndicator = false,
    slideTimer;

  const renderDots = function (style = "") {
    dotIndicator = true;
    const dotsContainer = queryTarget.querySelector(".dots__container");
    for (let i = 0; i <= counter; i++) {
      dotsContainer.insertAdjacentHTML(
        "beforeend",
        `<div class="dot ${style}" data-slide="${i}"></div>`
      );
    }
  };

  const removeActiveDot = function () {
    if (!dotIndicator) return;
    queryTarget
      .querySelectorAll(".dot")
      .forEach((dot) => dot.classList.remove("dot--active"));
  };

  const defaultActive = function () {
    if (!dotIndicator) return;
    queryTarget
      .querySelector(`[data-slide = '0']`)
      .classList.add("dot--active");
  };
  const addActiveDot = function (index) {
    if (!dotIndicator) return;
    queryTarget
      .querySelector(`[data-slide = "${index}"]`)
      .classList.add("dot--active");
  };
  // allSlides.forEach((slide, index, arr) => {

  // });

  const translateX = function (position) {
    allSlides.forEach((slide) => {
      slide.style.transform = `translateX(${position}px)`;
    });
  };

  const scaleTranslate = function (position, slide) {
    slide.style.transform = `translateX(${position}px) scale(${scaleAnimation})`;
  };

  const touchStart = function (e) {
    isDragging = true;
    startPostion = e.type.includes("mouse") ? e.pageX : e.touches[0].clientX;
  };

  const touchMove = function (e) {
    if (isDragging) {
      currentPosition = e.type.includes("mouse")
        ? e.pageX
        : e.touches[0].clientX;
      currentTranslate =
        (currentPosition - startPostion) * 1 + previousTranslate;
      translateX(currentTranslate);
      if (e.target.closest(".simpleslider__slide"))
        scaleTranslate(
          currentTranslate,
          e.target.closest(".simpleslider__slide")
        );
    }
  };

  const touchEnd = function (e) {
    isDragging = false;
    drag = currentPosition === 0 ? 0 : currentPosition - startPostion;
    if (Math.abs(drag) > slideWidth * 0.2) {
      console.log(currentTranslate - previousTranslate);
      if (currentTranslate - previousTranslate < 0) nextSlide();
      if (currentTranslate - previousTranslate > 0) prevSlide();
    } else translateX(previousTranslate);
    startPostion = 0;
    currentPosition = 0;
  };

  function jumpTo(index) {
    currentTranslate = -(slideWidth + computeGap()) * index;
    translateX(currentTranslate);
    previousTranslate = currentTranslate;
    removeActiveDot();
    addActiveDot(index);
    if (autoPlay) {
      clearInterval(slideTimer);
      slideTimer = setInterval(nextSlide, autoPlay);
    }
  }

  function nextSlide() {
    if (currentIndex >= counter) currentIndex = 0;
    else ++currentIndex;
    jumpTo(currentIndex);
  }

  function prevSlide() {
    if (currentIndex <= 0) currentIndex = counter;
    else currentIndex--;
    jumpTo(currentIndex);
  }
  if (showDots) {
    renderDots();
    defaultActive();
  }

  if (autoPlay) {
    slideTimer = setInterval(nextSlide, autoPlay);
  }

  eventSlider.querySelectorAll("img").forEach((img) => {
    img.addEventListener("dragstart", (e) => e.preventDefault());
  });

  const dotsIcons = document.querySelectorAll(".dot");
  dotsIcons.forEach((dot) => {
    dot.addEventListener("click", () => {
      jumpTo(dot.dataset.slide);
    });
  });

  eventSlider.addEventListener("touchstart", touchStart);
  eventSlider.addEventListener("touchend", touchEnd);
  eventSlider.addEventListener("touchmove", touchMove);

  eventSlider.addEventListener("mousedown", touchStart);
  eventSlider.addEventListener("mousemove", touchMove);
  eventSlider.addEventListener("mouseup", touchEnd);
  eventSlider.addEventListener("mouseleave", touchEnd);

  btnNext.addEventListener("click", nextSlide);
  btnPrev.addEventListener("click", prevSlide);
};
